// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { select, call, put, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as types from './constant';

import * as actions from './action';
import * as selectors from './selector';
import * as api from '../../../../service/API';

function* initChargedBillList({ payload }) {
  try {
    const { pathname } = payload;

    const pathArr = pathname.split('/');

    if (pathArr[1] === 'chargedBill') {
      //   yield put(actions.getBill());
      yield put(openSubMenu('payment'));
      yield put(handleClickSubMenu('chargedBill'));
    }
  } catch (error) {}
}

export function* watcherInitChargedBillList() {
  yield takeLatest(LOCATION_CHANGE, initChargedBillList);
}

function* getBill() {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);

    const { id, companyName, isPay, date, is100mClub } = searchingKeywords;

    let _date;
    const isSearching = Object.keys(searchingKeywords).includes('date');
    if (!isSearching) {
      const today = moment();
      if (today.date() < 5) {
        _date = {
          year: today.year(),
          month: today.subtract(1, 'month').month(),
        };
      } else {
        _date = {
          year: today.year(),
          month: today.month(),
        };
      }
    } else {
      _date = {
        year: moment(date, 'YYYY-MM').year(),
        month: moment(date, 'YYYY-MM').month(),
      };
    }

    const { data } = moment(_date).isBefore(moment('2022-10'))
      ? yield call(api.payment.getBill, _date, {
          accountEmail: id,
          accountCompanyName: companyName,
          is100mClub,
          isPay:
            isPay === '미납'
              ? 'n'
              : isPay === '완납'
              ? 'y'
              : isPay === '부분납'
              ? 'p'
              : isPay === '연체'
              ? 'o'
              : isPay === '미청구'
              ? 'u'
              : undefined,
        })
      : yield call(api.payment.getPPUBills, _date, {
          accountEmail: id,
          accountCompanyName: companyName,
          is100mClub,
          isPay:
            isPay === '미납'
              ? 'n'
              : isPay === '완납'
              ? 'y'
              : isPay === '부분납'
              ? 'p'
              : isPay === '연체'
              ? 'o'
              : isPay === '미청구'
              ? 'u'
              : undefined,
        });

    // DESC: 검색 결과에서 청구요금, 납부요금, 미납요금, 크레딧 차감, 이용요금 합산을 보여주기 위한 로직
    let totalAmount = {
      chargingPrice: new BigNumber(0),
      chargedPrice: new BigNumber(0),
      unChargedPrice: new BigNumber(0),
      totalPrice: new BigNumber(0),
      credit: new BigNumber(0),
      usedCost: new BigNumber(0),
      surtax: new BigNumber(0),
    };

    data.forEach((row) => {
      const {
        totalPrice,
        usedCredit,
        chargedPrice,
        chargingPrice,
        unChargedPrice,
        surtax,
        usedCost,
      } = row;

      // 사용금액
      totalAmount.totalPrice = totalAmount.totalPrice.plus(totalPrice || 0);

      // 이용금액
      totalAmount.usedCost = totalAmount.usedCost.plus(usedCost || 0);

      totalAmount.credit = totalAmount.credit.plus(usedCredit || 0);
      totalAmount.surtax = totalAmount.surtax.plus(surtax || 0);
      // 청구금액 (** 상호 확인필요)
      totalAmount.chargedPrice = totalAmount.chargedPrice.plus(
        chargedPrice || 0
      );

      // 납부금액 (** 상호 확인필요)
      totalAmount.chargingPrice = totalAmount.chargingPrice.plus(
        chargingPrice || 0
      );

      // 미납금액
      totalAmount.unChargedPrice = totalAmount.unChargedPrice.plus(
        unChargedPrice || 0
      );
    });

    totalAmount = {
      chargingPrice: totalAmount.chargingPrice.toNumber(),
      chargedPrice: totalAmount.chargedPrice.toNumber(),
      unChargedPrice: totalAmount.unChargedPrice.toNumber(),
      totalPrice: totalAmount.totalPrice.toNumber(),
      credit: totalAmount.credit.toNumber(),
      usedCost: totalAmount.usedCost.toNumber(),
      surtax: totalAmount.surtax.toNumber(),
    };
    
    // 검색결과가 20개 이상일 경우!!!!!
    if (data && data.length > 20) {
      let obj = {};

      const rowIndex = yield select(selectors.rowIndexSelector);

      let resDivide = Math.floor(data.length / 20);
      const odd = data.length % 20;
      if (odd) resDivide = resDivide + 1;
      let startIndex = 0;
      let endIndex = 20;

      for (let i = 1; i <= resDivide; i++) {
        if (i === resDivide) {
          obj = {
            ...obj,
            [i]: data.slice(startIndex),
          };
        } else
          obj = {
            ...obj,
            [i]: data.slice(startIndex, endIndex),
          };

        startIndex += 20;
        endIndex += 20;
      }

      yield put(
        actions.getBillSuccess(
          obj,
          resDivide - 1 < rowIndex,
          resDivide,
          totalAmount
        )
      );
    } else yield put(actions.getBillSuccess(data, true, 1, totalAmount));
  } catch (error) {
    console.log('ERR: GET_CURRENT_BILL', error);
    // console.log('ERR: GET_CURRENT_BILL', error && error.response);
    yield put(actions.getBillSuccess([]));
    yield put(actions.getBillError(true));
  }
}

export function* watcherGetBill() {
  yield takeLatest(types.GET_BILL, getBill);
}

function* clickChargedBillRow({ id, arr }) {
  try {
    const { id } = arr[1].id;
    yield put(actions.getAccountBill(id));

    const {
      data: {
        accountInfo: { status },
      },
    } = yield call(api.account.getAccountInfo, id);
    yield put(actions.getAccountStatusSuccess(status));
  } catch (error) {
    console.log('ERR: CLICK_CHARGED_BILL_ROW', error && error.response);
  }
}

export function* watcherClickChargedBillRow() {
  yield takeLatest(types.CLICK_CHARGED_BILL_ROW, clickChargedBillRow);
}

const handleDate = (isSearching, param, date) => {
  let _date;
  if (!isSearching) {
    const today = moment();
    if (today.date() < param) {
      _date = {
        year: today.year(),
        month: today.subtract(1, 'month').month() + 1,
      };
    } else {
      _date = {
        year: today.year(),
        month: today.month() + 1,
      };
    }
  } else {
    _date = {
      year: moment(date, 'YYYY-MM').year(),
      month: moment(date, 'YYYY-MM').month() + 1,
    };
  }
  return _date;
};

function* getAccountBill({ id }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);

    const { date } = searchingKeywords;

    const isSearching = Object.keys(searchingKeywords).includes('date');

    // 10월부터는 종량제 청구서를 가져오기 (10 === moment 9)
    let result;
    let _date;

    // TODO: 현재 테스트를 위해서 8로 실행

    const searchingDate = moment(date); // 검색 조건
    const criteria = moment('2022-10'); // 기준 연월
    const isBefore = criteria.isAfter(searchingDate);
    // true는 기준 이전으로 검색했다는 의미,  false는 이후

    if (isBefore) {
      console.log('this is NOT PPU -->');
      // 기존 청구서 가져오기
      _date = handleDate(isSearching, 10, date);
      const { data } = yield call(api.payment.getAccountBill, _date, id);
      result = data;
    } else {
      console.log('this is PPU -->');
      _date = handleDate(isSearching, 5, date);
      // 종량제 청구서 가져오기
      const { data } = yield call(api.payment.getPPUBill, {
        accountId: id,
        ..._date,
      });
      const {
        bills, // -> gamesFee[]
        gamesFee,
        subTotalCost, // 할인 까지 계산된 요금 (절사,크레딧차감 등은 적용 전)
        surtax,
        totalCost,
        calculatedSum,
        credit,
        beforeDiscount,
        free,
      } = data;

      const sumResults = {
        subTotalCost,
        beforeDC: beforeDiscount, // beforeDCSum,
        freePrice: free, // freePriceSum,
        chargePrice: new BigNumber(beforeDiscount).minus(free).toNumber(), // chargePrice === 합산금액 (이용-무료 계산해야함)
        // calculated: calculatedSum,
        credit,
        finalPrice: totalCost, // totalCost === 총 청구 금액
        surtax,
      };
      result = {
        bills: gamesFee,
        sumResults,
      };
    }

    yield put(actions.getAccountBillSuccess({ result, ..._date }));
  } catch (error) {
    console.log('ERR :GET_ACCOUNT_BILL', error);
    console.log('ERR :GET_ACCOUNT_BILL', error && error.response);
  }
}

export function* watcherGetAccountBill() {
  yield takeLatest(types.GET_ACCOUNT_BILL, getAccountBill);
}

function* getGameBill({ id, billType, gameTitle }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);

    let date;

    const isSearching = searchingKeywords.date || false;

    if (!isSearching) {
      const today = moment();
      if (today.date() < 5) {
        date = {
          year: today.year(),
          month: today.subtract(1, 'month').month(),
        };
      } else {
        date = {
          year: today.year(),
          month: today.month(),
        };
      }
    } else {
      const searchingDate = moment(searchingKeywords.date);
      date = {
        year: searchingDate.year(),
        month: searchingDate.month(),
      };
    }

    const { data } = yield call(api.payment.getGameBill, date, id);

    // billType ( dau / share / db) 분기해서 리턴 값 처리
    let obj;
    for (let key in data) {
      if (billType === key) obj = data[key];
    }

    yield put(actions.getGameBillSuccess(obj, gameTitle, id));
  } catch (error) {
    console.log(error, 'GET GAME BILL ERROR - customErr');
  }
}

export function* watcherGetGameBill() {
  yield takeLatest(types.GET_GAME_BILL, getGameBill);
}

function* getGamePPUBill({ id, billType, gameTitle, selectedDate }) {
  try {
    const { date } = yield select(selectors.searchKeywordsSelector);

    const tmp = moment(date).subtract(1, 'month').format('YYYY-MM');

    const year = tmp.split('-')[0];
    const month = tmp.split('-')[1];

    const dateParams = {
      month,
      year,
      date: selectedDate,
    };

    const query = { type: billType, ...dateParams };

    const { data } = yield call(api.paymentNew.getCurrentGameBill, id, query);

    yield put(actions.getGamePPUBillSuccess(id, billType, gameTitle, data));
  } catch (error) {
    console.log(error, 'GET GAME BILL ERROR - customErr');
  }
}

export function* watcherGetGamePPUBill() {
  yield takeLatest(types.GET_GAME_PPU_BILL, getGamePPUBill);
}

function* updateAccountBill({ obj }) {
  try {
    // 검색어를 입력하지 않고 검색 : 현재 년월로 year, month
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);

    let year, month;

    const { id, billDate } = yield obj;

    const momentObj = yield moment(billDate, 'YYYY-MM');
    year = yield momentObj.year();
    month = yield momentObj.month() + 1;

    yield delete obj.id;
    yield delete obj.billDate;

    yield call(api.payment.updateAccountBill, year, month, id, obj);
    yield put(actions.updateAccountBillSuccess());
    yield put(actions.getBill());
  } catch (error) {
    console.log('ERR : UPDATE_ACCOUNT_BILL', error && error.response);
  }
}

export function* watcherUpdateAccountBill() {
  yield takeLatest(types.UPDATE_ACCOUNT_BILL, updateAccountBill);
}

function* updateGameChargingPrice({ billDate, chargingPrice }) {
  try {
    const id = yield select(selectors.gameIdSelector);
    const billType = yield select(selectors.gameBillTypeSelector);
    const gameTitle = yield select(selectors.gameTitleSelector);
    yield call(
      api.payment.updateGameChargingPrice,
      id,
      billDate,
      chargingPrice
    );
    yield put(actions.updateGameChargingPriceSuccess());
    yield put(actions.getGameBill(id, billType, gameTitle));
  } catch (error) {
    console.log('UPDATE_GAME_CHARGING_PRICE ERROR : ', error);
  }
}

export function* watcherUpdateGameChargingPrice() {
  yield takeLatest(types.UPDATE_GAME_CHARGING_PRICE, updateGameChargingPrice);
}

// function* initDeletedAccountList({ gameId }) {
// 	try {

//     } catch (error) {

//     }
// }

// export function* watcherInitDeletedAccountList() {
// 	yield takeLatest(types., initDeletedAccountList);
// }
