// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import {
  requestGet,
  requestPost,
  requestPut,
  requestDelete,
} from '../../utils/request';
import Qs from 'qs';

export const getCurrentBill = (params) => {
  const url = `payment/current/v1.2`;
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };
  return requestGet(url, options);
};
export const getCurrentAccountBill = (accountId, params) => {
  const url = `payment/current/account/v1.4/${accountId}`;
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};
export const getCurrentGameBill = (gameId, params) => {
  const url = `payment/current/game/v1.4/${gameId}`;
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, {
        arrayFormat: 'brackets',
      });
    },
  };

  return requestGet(url, options);
};

export const getBill = (date, params) => {
  const url = `payment/v1.1/${date.year}/${date.month + 1}`;
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };
  return requestGet(url, options);
};

export const getAccountBill = (date, accountId) => {
  const url = `payment/${date.year}/${date.month + 1}/account/${accountId}`;

  return requestGet(url);
};

export const getGameBill = (date, gameId) => {
  const url = `payment/${date.year}/${date.month + 1}/game/${gameId}`;

  return requestGet(url);
};

export const getOverdueBill = (params) => {
  const url = `payment/overdue`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const updateAccountBill = (year, month, accountId, data) => {
  const url = `payment/v1.1/${year}/${month}/account/${accountId}`;

  return requestPost(url, data);
};

// ************************************************************

export const updateStatus = (id, data) => {
  const url = `project/${id}/status`;

  return requestPost(url, data);
};

export const getProjectsCount = () => {
  const url = `project/count`;

  return requestGet(url);
};

export const getProjects = (params) => {
  const url = 'project/v1.2';
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const updateGameChargingPrice = (gameId, billDate, chargingPrice) => {
  const url = `payment/${gameId}/chargingPrice`;

  return requestPost(url, { billDate, chargingPrice });
};

export const addExtraBill = (data) => {
  const url = `/payment/current/game/extra/v1.2`;
  return requestPut(url, data);
};

export const deleteExtraBill = (gameId, inDate) => {
  const url = `/payment/current/game/extra/${gameId}/${inDate}`;
  return requestDelete(url);
};

export const updateExtraBill = (gameId, inDate, data) => {
  const url = `/payment/current/game/extra/${gameId}/${inDate}`;
  return requestPost(url, data);
};
