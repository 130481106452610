// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';
import _ from 'lodash';
import moment from 'moment';

import DatePicker from '../../../fnComponents/SingleDatePicker.1';

import * as actions from './store/action';
import { numberToString } from '../../../shared/common';
import categoryMapper from '../../../static/categorymapper.json';
import keyMapper from '../../../static/keymapper.json';
import BigNumber from 'bignumber.js';

const PayPerUseDetailModal = ({
  isOpen,
  handleOpenModal,
  gameTitle,
  searchKeywords,
  billType,
  gameBill,
  gameId,
}) => {
  const fromFirstToNow = () => {
    if (
      searchKeywords &&
      searchKeywords.status &&
      searchKeywords.status === '이달'
    ) {
      const firstOfThisMonth = moment('01', 'DD').format('YYYY.MM.DD');
      const atThisMoment = moment().format('YYYY.MM.DD');
      return `${firstOfThisMonth} - ${atThisMoment}`;
    }
    if (
      searchKeywords &&
      searchKeywords.status &&
      searchKeywords.status === '지난달'
    ) {
      const firstOfTheMonth = moment().subtract(1, 'month');
      const endOfTheMonth = firstOfTheMonth.endOf('month').format('YYYY.MM.DD');

      return `${firstOfTheMonth
        .set({ date: 1 })
        .format('YYYY.MM.DD')} - ${endOfTheMonth}`;
    }
  };

  const [hasSubCategory, setHasSubCategory] = useState([]);

  /**
   * @description 분류 및 항복별 단위를 리턴하는 함수
   * @param {*} key 항목 이름 (예. 호출수,사용시간 등)
   * @param {*} param 출력 구분 (사용량/무료제공/이용요금)
   * @param {*} strings 출력에 필요한 리턴될 t 함수의 파라미터
   */
  const unitHandler = useCallback(
    (key, param, strings) => {
      if (param === 'usedPrice') {
        return `${strings}원`;
      }
      if (billType === 'base') {
        if (param === 'rate') {
          return `${strings}원/호출`;
        }
        if (param === 'limitCount') {
          return `${strings}호출/월`;
        }
        return `${strings}호출`;
      }
      if (billType === 'db') {
        if (param === 'rate') {
          if (key === 'readDB' || key === 'writeDB')
            return `${strings}원/처리량`;
          return `${strings}원/GB`;
        }
        if (key === 'readDB' || key === 'writeDB')
          return param === 'limitCount'
            ? `${strings}처리량/월`
            : `${strings}처리량`;
        return param === 'limitCount' ? `${strings}GB/일` : `${strings}GB`;
      }
      if (billType === 'function') {
        if (key === 'duration' && param === 'rate') return `${strings}원/분`;
        if (key === 'callCount' && param === 'rate') return `${strings}원/호출`;
        if (key === 'duration')
          return param === 'limitCount' ? `${strings}분/월` : `${strings}분`;

        return param === 'limitCount' ? `${strings}호출/월` : `${strings}호출`;
      }
      if (billType === 'push') {
        if (param === 'rate') return `${strings}원/건`;
        if (param === 'limitCount') return `${strings}건/월`;
        return `${strings}건`;
      }
      if (billType === 'chat') {
        if (key === '트래픽' || key === 'Traffic') {
          if (param === 'limitCount') {
            return `${strings}GB/월`;
          }

          if (param === 'rate') {
            return `${strings}/GB`;
          }

          return `${strings}GB`;
        }
        if (key.includes('CCU')) {
          if (param === null) {
            return `${strings}초`;
          }
          if (param === 'limitCount') {
            return null;
          }
          if (param === 'rate') {
            return `${strings}/초`;
          }
        } else if (key === 'gameUser') {
          if (param === null) {
            return `${strings}호출`;
          }
          if (param === 'limitCount') return `${strings}호출/월`;
          if (param === 'rate') return `${strings}/호출`;
        } else {
          if (param === 'limitCount') {
            return null;
          }
          if (param === 'rate') {
            return `${strings}/월`;
          }
          if (key.includes('기본 요금') || key.includes('Default fee')) {
            return `약 ${strings}일/1개월`;
          }
          if (key) {
            return `${strings}호출`;
          }
        }
      }
      return null;
    },
    [billType]
  );

  const makeRows = () => {
    if (gameBill) {
      const { list } = gameBill;
      const arr = [];
      Object.keys(list).forEach((key, index) => {
        const {
          callCount,
          cost,
          freeCost,
          limitCount,
          price,
          subCategory,
          usedPrice,
        } = list[key];

        const subs = list[key].subCategory
          ? Object.keys(list[key].subCategory)
          : [];
        const row = (
          <>
            <tr>
              <td>
                {hasSubCategory.includes(index) ? (
                  <IoMdArrowDropdown
                    className="clickable"
                    onClick={() => {
                      const cloned = _.clone(hasSubCategory);

                      setHasSubCategory(
                        cloned.filter((item) => index !== item)
                      );
                    }}
                  />
                ) : (
                  <IoMdArrowDropright
                    className="clickable"
                    onClick={() => {
                      const cloned = _.clone(hasSubCategory);
                      cloned.push(index);
                      setHasSubCategory(cloned);
                    }}
                  />
                )}
                {categoryMapper[key] ?? key}
                {/* 채팅 요금에 대한 텍스트는 카테고리에 없음. key 파라미터를 이용해야 함 */}
              </td>
              <td>
                {unitHandler(key, null, numberToString(callCount, 6, true))}
              </td>
              {isSelected ? null : (
                <td>
                  {unitHandler(
                    key,
                    'limitCount',
                    numberToString(limitCount, 6, true)
                  )}
                </td>
              )}
              <td>{unitHandler(key, 'rate', price)}</td>
              <td>
                {unitHandler(
                  key,
                  'usedPrice',
                  numberToString(usedPrice, 6, true)
                )}
              </td>
              {isSelected ? null : (
                <td>
                  {unitHandler(key, 'usedPrice', numberToString(cost, 6, true))}
                </td>
              )}
            </tr>

            {subs.map((sub) => (
              <tr
                className={
                  hasSubCategory.includes(index)
                    ? 'show-subCategory'
                    : 'no-subCategory'
                }
              >
                <td className="padd-left-10">{keyMapper[sub]}</td>
                <td>{unitHandler(key, null, list[key].subCategory[sub])}</td>
              </tr>
            ))}
          </>
        );
        arr.push(row);
      });
      return arr.length ? (
        arr
      ) : (
        <tr>
          <td>사용 내역이 없습니다.</td>
        </tr>
      );
    }
  };
  const makeColumsRow = () => {
    return (
      isSelected
        ? ['항목', '사용량', '요금단가', '이용금액']
        : ['항목', '사용량', '무료제공', '요금단가', '이용금액', '청구금액']
    ).map((column, i) => {
      const forKey = i;
      return <th key={forKey}>{column}</th>;
    });
  };

  const [isSelected, setIsSelected] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');

  const [focusByIcon, setFocus] = useState(null);
  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };
  const [inputObj, handleInput] = useState(initInputObj);

  const dispatch = useDispatch();

  const onGetSearchKeywords = ({ inDate }) => {
    dispatch(
      actions.getCurrentGameBill(
        gameId,
        billType,
        gameTitle,
        moment(inDate).date()
      )
    );
  };
  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader>
        {gameTitle}
        <div>{fromFirstToNow()}</div>
      </ModalHeader>
      <ModalBody>
        <div
          className="m-radio-inline"
          style={{ marginBottom: '15px', marginLeft: '42px' }}
        >
          <label className="m-radio">
            <input
              onChange={(e) => {
                setIsSelected(false);
                dispatch(
                  actions.getCurrentGameBill(gameId, billType, gameTitle)
                );
              }}
              type="radio"
              checked={!isSelected}
            />
            전체
            <span />
          </label>
          <label className="m-radio">
            <input
              onChange={(e) => {
                setIsSelected(true);
                onGetSearchKeywords({ inDate: moment().format('YYYY-MM-DD') });
              }}
              type="radio"
              checked={isSelected}
            />
            선택
            <span />
          </label>

          <div style={{ width: '26%', display: 'inline-block' }}>
            <DatePicker
              disabled={!isSelected}
              startDate={startDate}
              endDate={endDate}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              initInputObj={initInputObj}
              initSearchFilter={inputObj.init}
              searchParam="signUpDate"
              onGetSearchItem={onGetSearchKeywords}
              setFocus={setFocus}
              focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
            />
          </div>
        </div>

        <Table className="main-table">
          <thead className={isSelected ? 'column-4-payperuse' : 'column-6'}>
            <tr>{makeColumsRow()}</tr>
          </thead>
          <tbody className={isSelected ? 'column-4' : 'column-6'}>
            {makeRows()}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            handleOpenModal(false);
            setIsSelected(false);
            setHasSubCategory([]);
          }}
        >
          닫기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayPerUseDetailModal;
