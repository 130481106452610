// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import * as types from './constant';

export function initCurrentChargeList() {
  return {
    type: types.INIT_CURRENT_CHARGE_PAYPERUSE_LIST,
  };
}
export function clickCurrentChargeRow(id, arr) {
  return {
    type: types.CLICK_CURRENT_CHARGE_PAYPERUSE_ROW,
    id,
    arr,
  };
}
export function getBackToMainTable() {
  return {
    type: types.GET_BACK_TO_PAYPERUSE_MAIN_TABLE,
  };
}
export function handleOpenLayerdModal() {
  return {
    type: types.HANDLE_OPEN_PAYPERUSE_LAYERD_MODAL,
  };
}
export function handleCloseLayerdModal() {
  return {
    type: types.HANDLE_CLOSE_PAYPERUSE_LAYERD_MODAL,
  };
}
export function getCurrentBill(obj) {
  return {
    type: types.GET_CURRENT_PAYPERUSE_BILL,
    obj,
  };
}
export function getCurrentBillSuccess(rows, newData) {
  return {
    type: types.GET_CURRENT_PAYPERUSE_BILL_SUCCESS,
    rows,
    newData,
  };
}
export function getCurrentAccountBill(id, query) {
  return {
    type: types.GET_CURRENT_ACCOUNT_BILL_PAYPERUSE,
    id,
    query,
  };
}
export function getCurrentAccountBillSuccess(accountBill) {
  return {
    type: types.GET_CURRENT_ACCOUNT_BILL_PAYPERUSE_SUCCESS,
    accountBill,
  };
}
export function getCurrentGameBill(id, billType, gameTitle, date) {
  return {
    type: types.GET_CURRENT_GAME_BILL_PAYPERUSE,
    id,
    billType,
    gameTitle,
    date,
  };
}
export function getCurrentGameBillSuccess(gameBill, gameTitle, billType) {
  return {
    type: types.GET_CURRENT_GAME_BILL_PAYPERUSE_SUCCESS,
    gameBill,
    gameTitle,
    billType,
  };
}

export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS_CURRENT_PAYPERUSE,
    obj,
  };
}

export function addExtraBill(obj) {
  return {
    type: types.ADD_EXTRA_BILL_PAYPERUSE,
    obj,
  };
}
export function updateExtraBill(gameId, inDate, obj) {
  return {
    type: types.UPDATE_EXTRA_BILL_PAYPERUSE,
    gameId,
    inDate,
    obj,
  };
}
export function deleteExtraBill(gameId, inDate) {
  return {
    type: types.DELETE_EXTRA_BILL_PAYPERUSE,
    gameId,
    inDate,
  };
}
export function addExtraBillSuccess(obj) {
  return {
    type: types.ADD_EXTRA_BILL_PAYPERUSE_SUCCESS,
    obj,
  };
}
export function updateExtraBillSuccess(obj) {
  return {
    type: types.UPDATE_EXTRA_BILL_PAYPERUSE_SUCCESS,
    obj,
  };
}
export function deleteExtraBillSuccess(obj) {
  return {
    type: types.DELETE_EXTRA_BILL_PAYPERUSE_SUCCESS,
    obj,
  };
}

export function getAccountStatusSuccess(status) {
  return {
    type: types.GET_ACCOUNT_STATUS_PAYPERUSE_SUCCESS,
    status,
  };
}
export function sortAscCurrentAccountList(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_CURRENT_PAYPERUSE_ACCOUNT_LIST,
    rows,
    isSearched,
    tableType,
  };
}
export function sortDescCurrentAccountList(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_CURRENT_PAYPERUSE_ACCOUNT_LIST,
    rows,
    isSearched,
    tableType,
  };
}
