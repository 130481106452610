// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import React, { useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table } from 'reactstrap';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import ShareChargeDetailModal from './ShareChargeDetailModal';
import PayPerUseDetailModal from './PayPerUseDetailModal';

import * as actions from './store/action';
import * as selectors from './store/selector';

const CurrentChargeDetail = ({
  onGetBackToMainTable,
  onGetGameBill,
  onUpdateGameChargingPrice,
  detailColumns,
  projectChargeList,
  gameBill,
  gamePPUBill,
  gameTitle,
  account,
  status,
  searchKeywords,
  isUpdateGameChargingPriceDone,
  isPay,
}) => {
  const [whatToShowInDetail, handleClick] = useState([]);
  const [isOpen, handleOpenModal] = useState(false);
  const [billType, handleCheckBillType] = useState('');

  const handleCollapse = (e, detail) => {
    if (whatToShowInDetail.includes(e.target.textContent)) {
      const filtered = whatToShowInDetail.filter(
        (pjtName) => pjtName !== e.target.textContent
      );
      return handleClick(filtered);
    } else handleClick([...whatToShowInDetail, e.target.textContent]);
    // 플젝이 클릭된다면 whatToShowInDetail에 플젝명을 할당
  };

  const makeColumsRow = (columns) => {
    if (columns.length)
      return columns.map((column, i) => {
        const forKey = i;
        return <th key={forKey}>{column.label}</th>;
      });
  };

  const mapBillPlanName = (value, type) => {
    switch (value) {
      case 'dau':
        return 'DAU 요금';
      case 'base':
        return '뒤끝베이스 요금';
      case 'push':
        return '푸시 요금';
      case 'function':
        return '뒤끝펑션 요금';
      case 'share':
        if (type === 'Silver') return '실버패스 요금';
        if (type === 'Gold') return '골드패스 요금';
        if (type === 'Diamond') return '다이아패스 요금';
        if (type === 'Enterprise') return '엔터프라이즈 요금';
        break;
      case 'db':
        return 'DB 요금';
      case 'chat':
        return '채팅 요금';

      default:
        break;
    }
  };

  const makeRows = (rows) => {
    if (rows.length) {
      const rowsSet = rows.map((row) => {
        return (
          <React.Fragment key={row.gameId}>
            {/* 게임타이틀이 포함되는 로우 생성 */}
            <tr>
              <td className="clickable-td" onClick={handleCollapse}>
                {row.gameTitle}
              </td>
              {/* 이용금액 */}
              <td>{replaceLocaleString(row.beforeDC)}</td>
              {/* 무료제공 */}
              <td>
                {row.freePrice > 0 ? '-' : null}{' '}
                {replaceLocaleString(row.freePrice)}
              </td>
              {/* 합산금액 */}
              <td>{replaceLocaleString(row.chargePrice)}</td>
              {/* TODO: month 비교값 체크 */}
              {moment(searchKeywords.date).month() < 8 &&
              moment(searchKeywords.date).year() === 2022 ? null : (
                // 요금할인
                <td>{row.discountRate ? '할인적용' : '할인미적용'}</td>
              )}

              {moment(searchKeywords.date).month() < 8 &&
              moment(searchKeywords.date).year() === 2022 ? null : row.cost ? (
                <td>{replaceLocaleString(row.cost)}</td>
              ) : (
                <td>0</td>
              )}
            </tr>

            {whatToShowInDetail.includes(row.gameTitle) &&
              row.chargeDetail.map((sub, i) => {
                const forKey = i;

                if (sub) {
                  const billType = Object.keys(sub)[0];
                  if (billType && billType !== 'extra')
                    return (
                      <tr className="subChargeRow" key={forKey}>
                        {/* 프로젝트 이름 */}
                        <td
                          className="subCharge clickable-td"
                          onClick={async () => {
                            handleCheckBillType(billType);
                            handleOpenDetailModal(
                              true,
                              row.gameId,
                              billType,
                              row.gameTitle,
                              [
                                'base',
                                'push',
                                'function',
                                'db',
                                'chat',
                              ].includes(billType)
                            );
                          }}
                        >
                          {mapBillPlanName(
                            billType,
                            sub.share && sub.share.type
                          )}
                        </td>
                        {/* 이용금액 */}
                        <td>{replaceLocaleString(sub[billType].beforeDC)}</td>
                        {/* 무료제공 */}
                        <td>
                          {sub[billType].freePrice > 0 ? '-' : null}{' '}
                          {replaceLocaleString(sub[billType].freePrice)}
                        </td>
                        {/* 합산금액 */}
                        <td>
                          {replaceLocaleString(sub[billType].chargePrice)}
                        </td>
                        {/* 할인적용 여부에 따라 분기 */}
                        {row.discountRate ? (
                          <td>{row.discountRate * 100 || ''}%</td>
                        ) : (
                          <td />
                        )}
                        {sub[billType].calculated ? (
                          <td>
                            {replaceLocaleString(sub[billType].calculated)}
                          </td>
                        ) : (
                          <td>0</td>
                        )}
                      </tr>
                    );
                  return billType
                    ? sub[billType].map((extra) => {
                        return (
                          <tr className="subChargeRow" key={forKey}>
                            <td className="subCharge clickable-td">
                              {extra.details}
                            </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td>{replaceLocaleString(extra.chargePrice)}</td>
                          </tr>
                        );
                      })
                    : null;
                }
              })}
          </React.Fragment>
        );
      });
      return rowsSet;
    } else {
      return (
        <tr>
          <td>데이터가 존재하지 않습니다.</td>
        </tr>
      );
    }
  };

  const dispatch = useDispatch();
  const [openPPUModal, setOpenPPUModal] = useState(false);

  const gameIdRef = useRef(null);

  const handleOpenDetailModal = (bool, gameId, btype, gameTitle, isPPU) => {
    if (isPPU) {
      setOpenPPUModal(bool);
      gameIdRef.current = gameId;
      if (bool) dispatch(actions.getGamePPUBill(gameId, btype, gameTitle));
    } else {
      handleOpenModal(bool);
      if (bool) onGetGameBill(gameId, btype, gameTitle);
    }
  };

  const paidPeriod = () => {
    // 특정 월로 검색하는 경우 고려해야 함
    const { date } = searchKeywords;

    if (date) {
      const today = moment(date, 'YYYY-MM');
      const thisMon = today.subtract(1, 'months');
      const endOfMonth = thisMon.endOf('month').date();
      return `${today
        .set({ month: thisMon.month(), date: 1 })
        .format('YYYY.MM.DD')}
          - ${today
            .set({ month: thisMon.month(), date: endOfMonth })
            .format('YYYY.MM.DD')}`;
    }

    //   조회 시점이 몇 일?
    const today = moment();
    if (today.date() < 10) {
      const thisMon = today.subtract(2, 'months');
      const endOfMonth = thisMon.endOf('month').date();
      return `${today
        .set({ month: thisMon.month(), date: 1 })
        .format('YYYY.MM.DD')}
     - ${today
       .set({ month: thisMon.month(), date: endOfMonth })
       .format('YYYY.MM.DD')}`;
    } else {
      const thisMon = today.subtract(1, 'months');
      const endOfMonth = thisMon.endOf('month').date();
      return `${today
        .set({ month: thisMon.month(), date: 1 })
        .format('YYYY.MM.DD')}
        - ${today
          .set({ month: thisMon.month(), date: endOfMonth })
          .format('YYYY.MM.DD')}`;
    }
  };

  const calcBill = (chargingPrice) => {
    // 화폐단위 정규식
    if (chargingPrice)
      return chargingPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (chargingPrice === 0) return chargingPrice;
  };

  const calcFunc = (param) => {
    const calculated = new BigNumber(parseFloat(param)).minus(
      Math.floor(parseFloat(param))
    );
    return calculated.toNumber();
  };

  const replaceLocaleString = (
    param,
    minimumFractionDigits,
    maximumFractionDigits = 6,
    option // option : { roundDown 원 미만 절사, roundDownByTen 10원 미만 절사 }
  ) => {
    if (param === 0) return 0;

    const res = Number(param).toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });

    if (option && option.roundDown) {
      return calcFunc(param);
    }

    if (option && option.roundDownByTen) {
      const floored = new BigNumber(param).minus(calcFunc(param)); // 원 미만 절사 금액

      const { credit, surtax, subTotalCost } = projectChargeList.sumResults;

      const res1 = floored.minus(credit).plus(surtax);

      const floored2 =
        Math.floor(
          Math.floor(parseFloat(res1.toNumber())) / 10 // 10원 미만 절사
        ) * 10;
      return res1.minus(floored2).toNumber();
    }
    return res;
  };

  return (
    <div className="m-content">
      <div className="row">
        <div className="col-md-12">
          <div className="m-portlet">
            <div className="m-portlet__body">
              <div className="m-portlet__body m-portlet__body--no-padding">
                <div className="m-invoice-2">
                  <div className="m-invoice__wrapper">
                    <div className="m-invoice__head">
                      <div className="m-invoice__container m-invoice__container--centered">
                        <div className="m-invoice__logo">
                          <div className="row">
                            <div className="col-lg-6">
                              <h5 className="modal-title">
                                이용기간
                                <i
                                  className="la la-question-circle"
                                  data-container="body"
                                  data-toggle="m-popover"
                                  data-placement="right"
                                  data-html="true"
                                  data-content="1일부터 오늘까지의 요금으로 부가세 및 할인(크레딧) 적용 전 금액입니다."
                                />{' '}
                                {paidPeriod()}
                              </h5>
                            </div>
                            <div className="col-lg-6 m--align-right">
                              <p>
                                {account}
                                <span className="m-badge m-badge--success m-badge--wide">
                                  {status === 'n' ? '정상' : '정지'}
                                </span>
                              </p>
                              <button
                                type="button"
                                onClick={onGetBackToMainTable}
                                className="btn btn-danger btn-sm cm_withdraw"
                              >
                                <i className="la" /> 목록
                              </button>
                            </div>
                          </div>
                          <h2>
                            청구 금액{' '}
                            <span className="m--font-danger">
                              {calcBill(
                                projectChargeList &&
                                  projectChargeList.sumResults &&
                                  projectChargeList.sumResults.finalPrice
                              )}
                            </span>{' '}
                            원
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="m-invoice__body m-invoice__body--centered">
                      <Table
                        id="custom-charge-table"
                        className="current-charge-detail table"
                      >
                        <thead className="main-table">
                          <tr>{makeColumsRow(detailColumns)}</tr>
                        </thead>

                        <tbody className="main-table">
                          {projectChargeList &&
                            projectChargeList.arr &&
                            makeRows(projectChargeList.arr)}
                          {projectChargeList &&
                            projectChargeList.sumResults && (
                              <React.Fragment>
                                <tr className="subTotal-row">
                                  <td>이용금액 합계</td>
                                  {console.log()}
                                  <td>
                                    {replaceLocaleString(
                                      projectChargeList.sumResults.beforeDC
                                    )}
                                  </td>
                                  <td>
                                    {projectChargeList.sumResults.freePrice > 0
                                      ? '-'
                                      : null}{' '}
                                    {replaceLocaleString(
                                      projectChargeList.sumResults.freePrice
                                    )}
                                  </td>
                                  <td>
                                    {replaceLocaleString(
                                      projectChargeList.sumResults.chargePrice
                                    )}
                                  </td>
                                  {moment(searchKeywords.date).month() < 8 &&
                                  moment(searchKeywords.date).year() ===
                                    2022 ? null : (
                                    <td> </td>
                                  )}
                                  {moment(searchKeywords.date).month() < 8 &&
                                  moment(searchKeywords.date).year() ===
                                    2022 ? null : (
                                    <td>
                                      {replaceLocaleString(
                                        projectChargeList.sumResults
                                          .subTotalCost
                                      )}
                                    </td>
                                  )}
                                </tr>
                                {moment(searchKeywords.date).month() < 8 &&
                                moment(searchKeywords.date).year() ===
                                  2022 ? null : (
                                  <tr className="subTotal-row">
                                    <td>원 미만 절사</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td></td>
                                    <td>
                                      {replaceLocaleString(
                                        projectChargeList.sumResults
                                          .subTotalCost,
                                        null,
                                        4,
                                        { roundDown: true }
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr className="subTotal-row">
                                  <td>크레딧 차감</td>
                                  <td> </td>
                                  <td> </td>
                                  {moment(searchKeywords.date).month() < 8 &&
                                  moment(searchKeywords.date).year() ===
                                    2022 ? null : (
                                    <>
                                      <td> </td>
                                      <td> </td>
                                    </>
                                  )}
                                  <td>
                                    {replaceLocaleString(
                                      projectChargeList.sumResults.credit
                                    )}
                                  </td>
                                </tr>
                                <tr className="subTotal-row">
                                  <td>부가가치세(10%)</td>
                                  <td> </td>
                                  <td> </td>
                                  {moment(searchKeywords.date).month() < 8 &&
                                  moment(searchKeywords.date).year() ===
                                    2022 ? null : (
                                    <>
                                      <td> </td>
                                      <td> </td>
                                    </>
                                  )}
                                  <td>
                                    {replaceLocaleString(
                                      projectChargeList.sumResults.surtax
                                    )}
                                  </td>
                                </tr>
                                {moment(searchKeywords.date).month() < 8 &&
                                moment(searchKeywords.date).year() ===
                                  2022 ? null : (
                                  <tr className="subTotal-row">
                                    <td>10원 미만 절사</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>
                                      {replaceLocaleString(
                                        projectChargeList.sumResults
                                          .subTotalCost,
                                        null,
                                        6,
                                        {
                                          roundDownByTen: true,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr className="subTotal-row">
                                  <td>청구금액</td>
                                  <td> </td>
                                  <td> </td>
                                  {moment(searchKeywords.date).month() < 8 &&
                                  moment(searchKeywords.date).year() ===
                                    2022 ? null : (
                                    <>
                                      <td> </td>
                                      <td> </td>
                                    </>
                                  )}
                                  <td>
                                    {calcBill(
                                      projectChargeList.sumResults.finalPrice
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            )}
                        </tbody>
                      </Table>
                    </div>
                    {isOpen && (
                      <ShareChargeDetailModal
                        isPay={isPay}
                        isUpdateGameChargingPriceDone={
                          isUpdateGameChargingPriceDone
                        }
                        onUpdateGameChargingPrice={onUpdateGameChargingPrice}
                        paidPeriod={paidPeriod}
                        gameTitle={gameTitle}
                        billType={billType}
                        gameBill={gameBill}
                        isOpen={isOpen}
                        handleOpenDetailModal={handleOpenDetailModal}
                      />
                    )}
                    {openPPUModal ? (
                      <PayPerUseDetailModal
                        gameId={gameIdRef.current}
                        searchKeywords={searchKeywords}
                        isPay={isPay}
                        isUpdateGameChargingPriceDone={
                          isUpdateGameChargingPriceDone
                        }
                        onUpdateGameChargingPrice={onUpdateGameChargingPrice}
                        paidPeriod={paidPeriod}
                        gameTitle={gameTitle}
                        billType={billType}
                        gameBill={gamePPUBill.gamePPUBill}
                        isOpen={openPPUModal}
                        handleOpenModal={setOpenPPUModal}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  detailColumns: selectors.detailColumnsSelector,
  projectChargeList: selectors.projectChargeListSelector,
  gameBill: selectors.gameBillSelector,
  gamePPUBill: selectors.gamePPUBillSelector,
  gameTitle: selectors.gameTitleSelector,
  account: selectors.idSelector,
  status: selectors.statusSelector,
  chargingPrice: selectors.chargingPriceSelector,
  searchKeywords: selectors.searchKeywordsSelector,
  isUpdateGameChargingPriceDone:
    selectors.isUpdateGameChargingPriceDoneSelector,
  isPay: selectors.isPaySelector,
});

const mapDispatchToProps = (dispatch) => ({
  // handleCloseProjectModal: () => dispatch(actions.handleCloseProjectModal()),
  // onConfirmChangeStatus: value => dispatch(actions.confirmChangeStatus(value)),
  // onSaveInput: value => dispatch(actions.saveInput(value)),
  onHandleOpenLayerdModal: () => dispatch(actions.handleOpenLayerdModal()),
  onHandleCloseLayerdModal: () => dispatch(actions.handleCloseLayerdModal()),
  onGetGameBill: (id, billType, gameTitle) =>
    dispatch(actions.getGameBill(id, billType, gameTitle)),
  onUpdateGameChargingPrice: (billDate, chargingPrice) =>
    dispatch(actions.updateGameChargingPrice(billDate, chargingPrice)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentChargeDetail);
